<template>
  <section class="change--pass-wrapper">
    <div class="title--text">
      {{ $t('referralCode.title') }}
    </div>
    <hr />
    <div class="referral--how-to">
      <div class="title--text">{{ $t('referralCode.howToTitle') }}</div>
      <div class="how--to-content">
        <div class="how--to-number">1</div>
        <div class="how--to-text">{{ $t('referralCode.howTo1') }}</div>
      </div>
      <div class="how--to-content">
        <div class="how--to-number">2</div>
        <div class="how--to-text">{{ $t('referralCode.howTo2') }}</div>
      </div>
      <div class="how--to-content">
        <div class="how--to-number">3</div>
        <div class="how--to-text">{{ $t('referralCode.howTo3') }}</div>
      </div>
      <div class="how--to-content">
        <div class="how--to-number">4</div>
        <div class="how--to-text">{{ $t('referralCode.howTo4') }}</div>
      </div>
    </div>
    <hr />
    <div class="share--section">
      <div>
        <div class="title--text">{{ $t('referralCode.myReferralCode') }}</div>
        <div
          id="copyReferralCode"
          class="referral--code"
          style="position: relative"
          @click="copyToClipboard(user ? user.referral_code : '')"
          v-show="user.referral_code !== null"
        >
          <span>{{ user ? user.referral_code : '' }}</span>
          <v-icon>mdi-content-copy</v-icon>
          <popover v-show="copyStatus"></popover>
        </div>

        <button
          class="btn btn-primary ml-0 mt-3"
          @click="showReferralCode"
          v-show="user.referral_code === null"
        >
          <span>{{ $t('referralCode.seeReferralButton') }}</span>
        </button>
      </div>
      <button class="btn btn-primary" @click="share" v-show="user.referral_code !== null">
        <img class="share--icon" src="@/assets/img/icons/share-2.svg" alt="Bagikan" />
        <span>{{ $t('general.share') }}</span>
      </button>
    </div>
    <hr />
    <div class="benefit--section">
      <div class="title--text">{{ $t('referralCode.totalBenefit') }}</div>
      <div class="benefit--text">{{ `${referralBenefits} ${$t('general.coin')}` }}</div>
    </div>
    <hr />
    <div class="referral--terms">
      <div class="title--text">{{ $t('referralCode.termTitle') }}</div>
      <ol>
        <li>
          <div class="term--content">{{ $t('referralCode.term1') }}</div>
        </li>
        <li>
          <div class="term--content">{{ $t('referralCode.term2') }}</div>
        </li>
        <li>
          <div class="term--content">{{ $t('referralCode.term3') }}</div>
        </li>
        <li>
          <div class="term--content">{{ $t('referralCode.term4') }}</div>
        </li>
      </ol>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import Popover from '@/components/utils/popover.vue';

export default {
  mixins: [HelperMixin],
  components: { Popover },
  data: () => ({
    copyStatus: null,
    referralBenefits: 0,
  }),
  computed: {
    ...mapState({
      user: (state) => state.global.user,
    }),
  },
  methods: {
    showReferralCode() {
      this.$swal(this.$t('booking.header'), this.$t('referralCode.seeReferralWarning'), 'warning');
    },
    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      this.handleCopyStatus(value);
    },
    async handleCopyStatus(value) {
      this.copyStatus = value;
      let self = this;
      setTimeout(() => {
        self.copyStatus = null;
      }, 2000);
    },
    share() {
      let text = this.$i18n.t('referralCode.share1') + ':';
      text += '\n\n';
      text += this.user ? this.user.referral_code : '';
      text += '\n\n';
      text += this.$i18n.t('referralCode.share2');
      text += '\n\n';
      text +=
        this.$i18n.t('referralCode.playStore') + ': ' + this.$i18n.t('referralCode.playStoreUrl');
      text += '\n';
      text +=
        this.$i18n.t('referralCode.appStore') + ': ' + this.$i18n.t('referralCode.appStoreUrl');
      window.open('https://wa.me/?text=' + encodeURIComponent(text), '_blank');
    },
  },
  async mounted() {
    this.referralBenefits = await this.$store.dispatch('profile/getReferralBenefits');
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.referral--how-to {
  .title--text {
    margin-bottom: 12px;
  }
  .how--to-content {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .how--to-number {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      min-width: 48px;
      height: 48px;
      margin-left: 8px;
      margin-right: 16px;
      color: $color-beliruma-1;
      background-color: $color-beliruma-1-light;
      border-radius: 50%;
      text-align: center;
      font-size: $font-xl;
      font-family: Poppins-Bold;
    }
    .how--to-text {
    }
  }
}
.share--section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media #{$smaller-phones} {
    display: initial;
  }
  .title--text {
    font-size: $font-md;
    font-family: Poppins-SemiBold;
    margin-bottom: 4px;
  }
  .referral--code {
    color: $color-beliruma-1;
    font-family: Poppins-Bold;
    font-size: $font-lg;
    cursor: pointer;
    .v-icon {
      margin-left: 8px;
      color: $color-beliruma-1;
    }
  }
  .btn {
    margin-left: 16px;
    @media #{$smaller-phones} {
      margin-left: 0;
      margin-top: 8px;
    }
  }
  .share--icon {
    width: 16px;
    color: $color-white;
    margin-right: 8px;
  }
}
.benefit--section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media #{$smaller-phones} {
    display: initial;
  }
  .title--text {
    font-size: $font-md;
    font-family: Poppins-SemiBold;
    margin-bottom: 4px;
  }
  .benefit--text {
    color: $color-beliruma-1;
    font-family: Poppins-Bold;
    font-size: $font-xxl;
  }
}
.referral--terms {
  .title--text {
    color: $color-beliruma-1;
    margin-bottom: 12px;
  }
  li {
    margin-bottom: 12px;
  }
  .term--content {
    margin-left: 12px;
    @media #{$smaller-phones} {
      margin-left: 0;
    }
  }
}
</style>
