<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <view-skeleton>
      <referral-code />
    </view-skeleton>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import ReferralCode from '@/components/referral-code/referral-code';

import DefaultHeadMixin from '@/mixins/defaultHead';
import ViewSkeleton from '@/components/utils/view-skeleton';

export default {
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    ReferralCode,
    ViewSkeleton,
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: this.$t('referralCode.title'),
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  methods: {},
  async mounted() {},
};
</script>
